import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ThankYouPage = () => (
  <Layout>
    <SEO title="Submitted" />
    <div className="bg-img">
      <div className="container pt-5">
        <div className="d-md-flex">
          <div className="w3ls_banner_txt">
            <h3
              className="w3ls_pvt-title"
              data-sal="slide-up"
              data-sal-delay="200"
              data-sal-duration="1000"
              data-sal-easing="ease-out-back"
            >
              Request
              <span>Received!</span>
            </h3>
            <p
              className="text-sty-banner w-75 text-white p-3 rounded"
              style={{"backgroundColor":"#21252970"}}
           
            >
              Thank you for submitting the request for a Free trial class with
              Coding Champs. We will get back to you through email or phone very
              shortly.
            </p>
            <div
              className="row"
              data-sal-easing="ease-out-back"
            >
              <a
                // href="/courses"
                style={{ marginLeft: `40px` }}
                rel="noopener noreferrer"
                className="button-orange mt-md-5 mt-4 read-more text-white"
              >
                Happy Coding!
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* </div>
      <h3
        //   data-sal="slide-up"
        //   data-sal-delay="200"
        //   data-sal-duration="1500"
        //   data-sal-easing="ease-out-back"
        className="tittle text-center font-weight-bold pt-5"
      >
        Request <span className="title-span">Received!</span>
      </h3>
      <div className="container">
        <p className="mt-4 text-center" style={{ fontSize: "20px" }}>
          Thank you for submitting the request for a Free trial class with
          Coding Champs. We will get back to you through email or phone very
          shortly.
          <br />
        </p>
        <p className="mt-4 text-center" style={{ fontSize: "20px" }}>
          If you have any further questions or concerns please contact us at +1
          (437) 421-1707.
        </p>
      </div> */}
    </div>
  </Layout>
)

export default ThankYouPage
